@import "../../assets/styles/theme";
.linear-loader {
    display: none;
    font-weight: bold;
    font-family: sans-serif;
    font-size: 30px;
    padding-bottom: 3px;
    background:linear-gradient($tertiary 0 0) bottom left/0% 3px no-repeat;
    animation:linear 2s linear infinite;
    position:absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    &.loading {
        display:block;
        &~div {
            opacity: 0.9;
            pointer-events: none;
        }
    }
}

@keyframes linear {to{background-size: 100% 3px}}