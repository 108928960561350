@import '/src/assets/styles/theme';

.checkbox-container {
    display: block;
    input[type='checkbox'] {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;             
    }
    input[type='checkbox']:checked + label:after {
        content: '';
        display: block;
        position: absolute;
        top: 2px;
        left: 9px;
        width: 6px;
        height: 14px;
        border: solid black;
        border-width: 0 1px 1px 0;
        transform: rotate(45deg);  
    }   
    label {
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        &:before {
                content:'';
                -webkit-appearance: none;
                background-color: transparent;
                border: 1px solid $primary;
                box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
                padding: 10px;
                display: inline-block;
                position: relative;
                vertical-align: middle;
                cursor: pointer;
                margin-right: 5px;
              }
    }
}
