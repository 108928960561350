@import '/src/assets/styles/theme';
@import '/src/assets/styles/breakpoints';
.calendar-container {
    background-color: $white;
    margin: 24px 0px;
    padding: 8px;
    @include tablet-up {
        padding: 16px;
    }
    .legend-container {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        margin-bottom: 20px;
        flex-wrap: wrap;
        .legend-item-container {
            display: flex;
            align-items: center;
            margin-right: 10px;

            .legend-item {
                width: 10px;
                height: 10px;
                border-radius: 5px;
                margin-right: 5px;
            }
        }
    }
    .fc-toolbar-title {
        position: absolute;
        top: 12px;
        @include tablet-up {
            top: 24px;
        }
    }
    .toggle-icons {
        display: flex;
        justify-content: flex-end;
    }
    .fc-event-time{
        display : none;
     }
}
