/* purgecss start ignore */
$grey: #b7b8b9;
.bg-grey {
    background-color: $grey;
}
.color-grey {
    color: $grey;
}
$black: #000000;
.bg-black {
    background-color: $black;
}
.color-black {
    color: $black;
}
$dark-grey: #555;
.bg-dark-grey {
    background-color: $dark-grey;
}
.color-grey {
    color: $dark-grey;
}

$white: #ffffff;
.bg-white {
    background-color: $white;
}
.color-white {
    /* purgecss ignore current */
    color: $white;
}

$tertiary :rgb(45, 175, 227);
.bg-tertiary {
    background-color: $tertiary;
}
.color-tertiary {
    color: $tertiary;
}

$primary: #908960;
.bg-primary {
    background-color: $primary;
}
.color-primary {
    color: $primary;
}

$secondary: #373737;
.bg-secondary {
    background-color: $secondary;
}
.color-secondary {
    color: $secondary;
}

$error: #B00020;
.bg-error {
    background-color: $error;
}
.color-error {
    color: $error;
}

$success: #28A745;
.bg-success {
    background-color: $success;
}
.color-success {
    color: $success;
}

$info: #007BFF;
.bg-info {
    background-color: $info;
}
.color-info {
    color: $info;
}

$warning: #FFC107;
.bg-warning {
    background-color: $warning;
}
.color-warning {
    color: $warning;
}.k-grid {
    border-radius: 4px;
    border-radius: 4px;
  }
  tr {
      cursor: pointer;  
  }
  .k-grid-header {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: $primary !important;
    color: $white !important;
    font-size: 16px !important;
    .k-icon {
      color: $white !important;
    }
    .k-filter-row {
        .k-filtercell .k-select {
            background-color: $primary;
        }
        .k-filtercell .k-button {
            background-color: $primary;            
            border: 0.8px solid white;
        }
        .k-filtercell-operator {
                display: none !important;
        }
    }   
  }
  .k-column-title {
      font-size: 14px !important;
  }
  .k-grid-pager {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .k-grid-toolbar {
     justify-content: flex-end !important;
     background-color: #f5f4f0 !important;
     border-top-left-radius: 4px;
     border-top-right-radius: 4px;
     &~.k-grid-header {
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
     }
  }

/* purgecss end ignore */