@import '/src/assets/styles/breakpoints.scss';

.typography {
    &--heading {
        font-size: 24px;
        line-height: 43.2px;
        font-weight: 400;
        @include mobile-up {  
            font-size: 28px;            
            line-height: 50.4px;
        }
        @include tablet-up {  
            font-size: 30px;           
            line-height: 54px; 
        }
    }
    &--subheading {
        font-size: 22px;
        line-height: 49.6px;
        font-weight: 400;
        @include mobile-up { 
            font-size: 24px;
            line-height: 43.2px;
        }
        @include tablet-up {  
            font-size: 28px;
            line-height: 50.4px;
        }
    }
    &--title {
        font-size: 18px;
        line-height: 27px;
        font-weight: 400;
        @include mobile-up { 
            font-size: 20px;
            line-height: 36px;
        }
        @include tablet-up { 
            font-size: 22px;
            line-height: 49.6px;
        }
    }
    &--subtitle {
        font-size: 18px;
        line-height: 27px;
        font-weight: 400;
        @include mobile-only {
            font-size: 16px;
            line-height: 28.8px;
        }        
    }
    &--body {
        font-size: 14px;
        line-height: 25.2px;
        font-weight: 400;
        @include mobile-only { 
            font-size: 13px;
            line-height: 23.4px;
        }
    }
    &--caption {
        font-size: 12px;
        line-height: 21.6px;
        opacity: 0.8;
        font-weight: 400;
        @include mobile-only { 
            font-size: 11px;
            line-height: 19.8px;
        }       
    }
}
