.tooltip {
    position: fixed;
    z-index: 999;
    background-color: #f2f2f2;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
    padding: 4px 10px;
    border-radius: 3px;
    pointer-events: none;
    text-align: left;
    white-space: nowrap;
    transition: opacity 0.5s;
    font-size: 14px;
    font-weight: 500;
    font-family: Arial, Helvetica, sans-serif;
    pointer-events: none;
    visibility: hidden;
  }
  