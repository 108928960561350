.loading-overlay-container {
    z-index: 10;
    width: 100%;
    height: 100%;
    background: transparent;
    transition: opacity 0.5s ease-in;
    position: relative;
    .loading-icon-container {
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        top: 50%;
        z-index: 25; 
        width: 60px;
        img {
            width: 100%;
        }
    }
    &.loader-active {
        .loading-content {
            opacity: 0.4;
            pointer-events: none;
        }
    }
}