.contract-performance {
    margin-top: 24px;
    .performance-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        .icons-container{
            display: flex;
            flex-direction: row;
        }
    }
    .week-seletion {
        margin-top: -16px;        
    }
    .input-select__menu-list {
        max-height: 160px;
    }
}