@import '../../assets/styles/breakpoints';
@import '../../assets/styles/theme';

.users {
    .callsheet-panel {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 100;
        transition: transform ease-in 3s;
        background-color: white;
        width: 100vw;
        max-width: 480px;
        height: 100%;
        padding: 24px;
        overflow-y: auto;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        .panel-actions {
            position: absolute;
            top: 6px;
            right: 6px;
            display: flex;
            .icon-button {                
                svg {
                    width: 36px;
                    height: 36px;
                    @include tablet-down {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
        .callsheet-days {
            margin-top: 24px;
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 20px;
            .day-items {
                margin-top: 12px;
                display: grid;
                grid-gap: 6px;
                .call-item {
                    display: flex;
                    padding: 12px;
                    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
                    .call-item-detail {
                        flex-grow: 1;
                        .inline-item {
                            display: flex;
                            align-items: center;
                            grid-gap: 6px;
                            margin: 4px 0;
                            svg {
                                width: 16px;
                                height: 16px;
                                color: $tertiary;
                            }
                            span {
                                font-size: 14px;
                            }
                        }
                    }
                    .call-item-time {
                        max-width: 130px;
                        min-width: 130px;
                        padding: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-left: 2px solid $primary;
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .css-4ljt47-MenuList {
        max-height: 170px;
    }
    .grid-actions {
        display: flex;
        svg {
            width: 16px;
        }
    }
    .user-role {
        display: flex;
        grid-gap: 16px;
        align-items: flex-start;
        .button {            
            min-width: 0;
            font-size: 12px;
        }
    }
    .caller-stations {
        .selection {
            display: grid;
            grid-template-columns: 1fr 60px;
            grid-gap: 16px;
            align-items: flex-start;
            .button {
                min-width: unset;
                margin: 0;
            }
        }
        .station-list {
            display: flex;
            flex-wrap: wrap;
            grid-gap: 12px;
            margin-bottom: 18px;
            .station-item {
                position: relative;  
                padding: 6px 32px 6px 12px;
                border-radius: 6px;
                box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;              
                .icon-button {
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding: 4px;
                    svg {
                        width: 16px;
                        height: 16px;
                    }
                }
            }

        }
        .css-4ljt47-MenuList {
            min-height: 170px;
        }
        .css-26l3qy-menu {
            position: relative;
            box-shadow: none;
            background-color: transparent;
        }
        .option {
            position: relative;
            .details {
                display: flex;
                grid-gap: 12px;
            }
            svg {
                position: absolute;
                z-index: 2;
                top: 0px;
                right: 6px;
                width: 18px;
                padding: 4px;
                &:hover {
                    opacity: 0.7;
                    transform: scale(1.1);
                    cursor: pointer;
                }
            }
        }
        .dropdown-indicator {
            svg {
                width: 20px;
                height: 20px;
                &:hover {
                    opacity: 0.7;
                    transform: scale(1.1);
                    cursor: pointer;
                }
            }
        }
    }    
}