@import '/src/assets/styles/_breakpoints.scss';
@import '/src/assets/styles/theme';

.station-search {
    max-width: $screen-desktop;
    margin: 0 auto;
    .search-filters {
        margin-top: 16px;
        background-color: #f5f4f0;
        padding: 24px 24px 56px 24px;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
            -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
            -moz-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        @include desktop-down {
            padding: 16px 16px 36px 16px;
        }
        @include tablet-down {
            padding: 8px 8px 24px 8px;
        }
        .search-actions {
            display: flex;
            grid-gap: 12px;
            justify-content: flex-end;
            align-items: flex-end;
            height: 100%;
        }
        .input-range-container{
            .slider-container{
                .marker-container:nth-of-type(2){
                    display: none;
                }
            }
        }
        .input-field{
            background: #fff;
            min-height: 13px;
        }
        .input-select__control{
            background-color: #fff;
        }
    }
    .searchbar {
        margin-top: -32px;
        @include desktop-down {
            margin-top: -24px;
        }
        @include tablet-down {
            margin-top: -16px;
        }
        display: flex;
        animation: fadeIn 1.5s;
        -webkit-animation: fadeIn 1s;
        -moz-animation: fadeIn 1s;
        -o-animation: fadeIn 1s;
        -ms-animation: fadeIn 1s;
        border-radius: 4px;
        height: 48px;
        margin-bottom: 12px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        .search-icon {
            background-color: $tertiary;
            padding: 12px;
            svg {
                font-size: 48px;
                color: $white;
            }
        }
        .search-input-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            .search-input {
                width: 100%;
                height: 32px;
               border: none;
               outline: none;
               font-size: 18px;
               padding-left: 16px;
            }
            .content-loading {
                position: absolute;
                right: 6px;
                top: 12px;
                img {
                    width: 60px;
                }
                svg {
                    width: 48px;
                    color: green;
                }
            }
        }
    }
    .selection-container {
        width: 90%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 120px;
        .button {
            width: 100px;
            min-width: 120px;
            font-size: 12px;
            background-color: $tertiary;
        }
        .selections {
            margin-right: 8px;
            display: flex; 
            flex-wrap: wrap;
            align-items: flex-start;
            grid-gap: 6px;
            .selection-item {
                display: flex;
                background-color: white;
                p {
                    margin: 0;
                    padding: 0;                    
                    font-size: 12px;                    
                    padding: 4px;                    
                    border: 0.5px solid $tertiary;
                    border-right: none;
                }
                .item-action {
                    background-color: $grey;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 24px;
                    &:hover {
                        cursor: pointer;                        
                        background-color: $error;
                        .close-icon {
                            color: white;
                        }
                    }
                    .close-icon {
                        font-size: 12px;
                    }
                }
            }
        }
    }
    .k-state-selected {
        z-index: 0 !important;
    }
    .cluster-list {
        margin-top: 16px;
        width: 90%;
        margin: 16px auto;
        min-height: 480px;
        .cluster-item {
            background-color: $white;
            padding: 16px;
            margin-bottom: 8px;
            box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
            -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
            -moz-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
            display: grid;
            grid-template-columns: 50% 50%;
            @include tablet-down {
                padding: 8px;
            }
            &.inactive {
                opacity: 0.6;
            }
        }
    }
    .k-pager-wrap {
        width: 90%;
        margin: 0 auto;
    }
    .synner-detail {
        .intro-detail {
            display: grid;
            grid-template-columns: 70px 1fr 1fr;
            grid-gap: 16px;
            align-items: center;
            .event-number {
                text-align: center;
                border: 1px solid $primary;
                padding: 4px;
                border-radius: 6px;
                width: 74px;
                float:right;
                .number {
                    font-size: 24px;
                    font-weight: bold; 
                    color: $info;                   
                }
                .text {
                    font-size: 12px;
                }
            }
        }
        table { 
            width: 100%;
            border-collapse: collapse;
            margin: 12px auto;
            tr:nth-of-type(odd) { 
                background: #eee; 
                }
            
            th {
                background: none; 
                font-weight: bold; 
                }
            
            td, th { 
                padding: 6px; 
                width: 33.33%;
                border: 1px solid #ccc; 
                text-align: left; 
                font-size: 18px;
                }
        }
    }
}