@import '/src/assets/styles/breakpoints';
@import '/src/assets/styles/theme';

.text-area-field-container {
    position: relative;
    margin-top: 0.5rem;
    margin-bottom: 1.2rem;
    height: 72px;
    width: 100%;
    display: flex;
    .input-field {
        width: 100%;
        border: 1px solid $secondary;
        font-size: 16px;
        border-radius: 5px;
        outline: none;
        padding: 0.8rem;
        background: none;
        &:focus {
            border: 1.5px solid $primary;
        }
        &.error {
            border: 1px solid $error;
        }
    }
    .error-text {
        color: $error;
        position: absolute;
        bottom:-16px;
        left: 5px;
        font-size: 12px;
        width: 80%;
    }
}
