@import "/src/assets/styles/breakpoints";
@import "/src/assets/styles/theme";

.card-container {
    animation: fadeIn 1.5s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;
    background: white;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    position: relative;
    border-radius: 8px;
    @include mobile-only {
        padding: 14px;
    }
    @include tablet-down {
        padding: 16px;
    }
    @include tablet-only {
        padding: 28px;
    }
    @include tablet-up {
        padding: 36px;
    }
    @include desktop-lg-up {
        padding: 40px;
    }   
    @include tablet-down {
        padding: 8px;
    }
    .card-action-header {
        margin-bottom: 16px;
    }
    .card-action-bar {        
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-top: 32px;
        button {
            min-width: 80px;
            font-size: 14px !important;
        }
        grid-gap: 12px;
    }
}