@import '/src/assets/styles/theme';
@import '/src/assets/styles/breakpoints';

.upload-container {    
    label {
        font-size: 14px;
        .file-area {
            border: 2px dotted $primary;
            background: rgba(0,0,0,0.1); 
            padding: 24px;
            @include tablet-down {
                padding: 16px;
            }   
            .default-input {
                position: absolute;
                top: 0;
                left: -10em;
                height: 0;
                width: 0;
                opacity: 0;
            } 
            .custom-input-details {
                display: flex;
                align-items: center;
                input[type="button"] {
                    height: 24px;
                }
                p {
                    margin-left: 8px;
                }
            }
        }        
    }
    .preview-upload {
        margin-top: 16px;
        width: 100%;
        display: flex;
        border: 1px solid $primary;
        border-radius: 6px;
        .preview-image {
            width: 160px;
            &.avatar {
                margin: 8px;
                width: 160px;
                height: 160px;
                border-radius: 50%;
                border: 1px solid $primary;
            }
        }
        pre {
            width: 160px;
        }
        .icon {
            width: 160px;
            height: 160px;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                width: 60px;
                height: 60px;
            }
        }        
        .preview-info {
            margin: 6px 12px 16px 12px;
            flex-grow: 1;
            position: relative;
            .info-item {
                display: grid;
                grid-template-columns: 60px 1fr;
                grid-column-gap: 12px;
            }
            .preview-action {
                position: absolute;
                right: 0px;
                bottom: 0px;
            }
        }
    }
}