@font-face {
    font-family: 'Roboto-Slab';
    src: url('../fonts/RobotoSlab-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto-Slab';
    src: url('../fonts/RobotoSlab-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto-Slab';
    src: url('../fonts/RobotoSlab-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto-Slab';
    src: url('../fonts/RobotoSlab-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto-Slab';
    src: url('../fonts/RobotoSlab-Medium.ttf')format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto-Slab';
    src: url('../fonts/RobotoSlab-SemiBold.ttf')format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto-Slab';
    src: url('../fonts/RobotoSlab-Bold.ttf')format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto-Slab';
    src: url('../fonts/RobotoSlab-ExtraBold.ttf')format('truetype');
    font-weight: 800;
    font-style: normal;
}