@import '/src/assets/styles/theme';
@import '/src/assets/styles/breakpoints';
.login-container {
    max-width: 360px;
    width: 100%;
    margin: 0 auto;
    .logo-container {
        width: 60%;
        margin: 0 auto;
        margin-bottom: 24px;
        @include mobile-up {
            margin-bottom: 32px;
        }
        img {
            width: 100%;
            animation: fadeIn 1.5s;
            -webkit-animation: fadeIn 1.5s;
            -moz-animation: fadeIn 1.5s;
            -o-animation: fadeIn 1.5s;
            -ms-animation: fadeIn 1.5s;
        }
    }
    hr {
        background-color: $secondary;
        padding-bottom: 1.4px;
        margin-bottom: 24px;        
    }
    .action-container {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        p {
            font-style: italic;
            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
}