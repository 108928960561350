.notification-container {
    position: fixed;
    bottom: 50px;
    right: 12px;
    width: 60%;
    max-width: 280px;
    padding: 8px 16px;
    background-color: white;
    display: flex;
    align-items: center;
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    filter: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08));
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    animation: fadeIn 1.5s;
    -webkit-animation: fadeIn 1.5s;
    -moz-animation: fadeIn 1.5s;
    -o-animation: fadeIn 1.5s;
    -ms-animation: fadeIn 2s;
    z-index: 50;
    .icon {
        font-size: 24px;        
    }
    .text {
        padding-left: 8px;
        font-size: 14px;
    }
    .close-icon {
        position:absolute;
        top: 10px;
        right: 10px;
        font-size: 14px;
        cursor: pointer;
        border-radius: 50%;
        &:hover {
            opacity: 0.7;
            transform: scale(1.1);
        }
    }
}
