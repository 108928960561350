.contract-adds {
    margin-top: 24px;
    .adds-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        .icons-container{
            display: flex;
            flex-direction: row;
        }
    }
}