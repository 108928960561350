.contract-detail {
    .detail-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .icons-container{
        display: flex;
        flex-direction: row;
    }
}