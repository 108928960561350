@import './_breakpoints.scss';
@import './_font.scss';
@import './_theme.scss';
@import "~@progress/kendo-theme-default/dist/all.scss";
@import './_utilities.scss';
main {
    padding: 0;
    margin: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    .app-container {
        flex-grow: 1;
        height: 100%;
    }
    font-family: 'Roboto-Slab';
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    overflow-x: hidden;
    @include tablet-down {
        font-size: 14px;
    }
}
p, h1, h2, h2, h4, h5 {
  margin: 0;
}
a {
  text-decoration: none !important;
  color: unset;
}

//input type button remove arrows
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

