@import '/src/assets/styles/_breakpoints.scss';
@import '/src/assets/styles/theme';
.forgot-password-page {    
    display: flex;
    justify-content: center;
    align-items: center;
    section {
        max-width: 480px;
        .logo-container {
            width: 60%;
            margin: 0 auto;
            margin-bottom: 24px;
            @include mobile-up {
                margin-bottom: 32px;
            }
            img {
                width: 100%;
                animation: fadeIn 1.5s;
                -webkit-animation: fadeIn 1.5s;
                -moz-animation: fadeIn 1.5s;
                -o-animation: fadeIn 1.5s;
                -ms-animation: fadeIn 1.5s;
            }
        }
        .action-container {
            display: flex;
            justify-content: space-between;
            margin-top: 16px;
            p {
                font-style: italic;
            }
        }
    }
}