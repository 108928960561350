@import '/src/assets/styles/breakpoints';
@import '/src/assets/styles/theme';

.multi-input-field-parent-container {
    position: relative;
    margin-top: 0.5rem;
    padding-bottom: 1.2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    .multi-input-field-controller{
        display: flex;
        height: 36px;
        .input-field {
            width: 100%;
            border: 1px solid $secondary;
            border-right: none;
            font-size: 16px;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            outline: none;
            padding: 0.8rem;
            background: none;
            &:focus {
                border: 1.5px solid $primary;
            }
            &.error {
                border: 1px solid $error;
            }
        }
        .input-label-outline {
            position: absolute;
            left: 1rem;
            top: 0.4rem;
            padding: 0 0.25rem;
            color: $secondary;
            transition: 0.3s;
        }
        .input-field:focus+ .input-label-outline {
            top: -0.5rem;
            left: 0.8rem;
            color: $primary;
            font-size: 12px;
            font-weight: 500;
            z-index: 1;
            background-color: white;
        }
        .input-field:not(:placeholder-shown)+ .input-label-outline{
            top: -0.5rem;
            left: 0.8rem;
            color: $primary;
            font-size: 12px;
            font-weight: 500;
            z-index: 1;
            background-color: white;
          }
        .icon-button{
            margin: 0;
            border: 1px solid $secondary;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            display: flex;
            align-content: center;
            svg{
                height: 18px;
                width: 18px;
            }
            &:hover,
            &:focus {
                opacity: 0.8;
                cursor: pointer;
                border-radius: 0px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                width: fit-content;
                background-color: rgba(0, 0, 0, 0.02);
                transform: scale(1);
            }
        }
    }
    .multi-input-value-container{
        margin-top: 0.5rem;
        display: flex;
        justify-content: flex-start;
        margin-right: 40px;
        grid-gap: 8px;
        flex-wrap: wrap;
        .multiple-value-text-input-item{
            padding: 4px 8px;
            background: #ccc;
            display: flex;
            align-items: center;
            border-radius: 8px;
            .icon-button{
                display: flex;
                margin: 0;
                align-content: center;
                svg{
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
    .error-text {
        color: $error;
        position: absolute;
        bottom:1px;
        left: 5px;
        font-size: 12px;
        width: 80%;
    }
}
