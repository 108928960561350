@import '/src/assets/styles/theme';

.input-range-container {
    .right-content,
    .left-content {
        width: 30%;
        max-width: 240px;
    }
    .left-content {
        margin-right: 16px;        
        text-align: left;
    }
    .right-content {
        margin-left: 16px;
        text-align: right;
    }
    .slider-container {
        position: relative;
        input[type='range'] {
            -webkit-appearance: none;
            outline: none;
            width: 100%;       
            border-radius: 5px;
            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                height: 12px;
                width: 12px;
                border-radius: 50%;
                background: $tertiary;
                margin-top: -2px;
                &:focus,
                &:hover {
                    opacity: 0.7;
                }
            }
            &::-moz-range-thumb {
                border-radius: 50%;
                background: $tertiary;
                height: 12px;
                width: 12px;
                margin-top: -2px;
                &:focus,
                &:hover {
                    opacity: 0.7;
                }
            }
            &::-ms-thumb {
                border-radius: 50%;
                background: $tertiary;
                height: 12px;
                width: 12px;
                margin-top: -2px;
                &:focus,
                &:hover {
                    opacity: 0.7;
                }
            }
            &::-webkit-slider-runnable-track {
                -webkit-appearance: none;
                width: 100%;
                cursor: pointer;
                border-radius: 5px;
                height: 8px;
            }
    
            &::-moz-range-track {
                width: 100%;
                cursor: pointer;
                border-radius: 5px;
                height: 8px;
            }
            &::-ms-track {
                width: 100%;
                cursor: pointer;
                border-radius: 5px;
                height: 8px;
            }
        }

    }
    .marker-container {
        width: 100%;
        position: absolute;
        margin: 4px 0px;
        display: flex;
        justify-content: space-between;
        .marker {
            font-size: 10px;
            opacity: 0.8;
            line-height: 0;
        }
        .slider-value {
            position: absolute;
            left: 75%;
            top: -20px;
            font-size: 12px;
        }
    }

    
}
