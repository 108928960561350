@import '/src/assets/styles/breakpoints';
@import '/src/assets/styles/theme';

.button {
    min-width: 150px;
	max-width: 320px;
	padding: 0.6em 1.2em;
	border: none;
    outline: none;    
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin: 8px 0px;
    justify-content: center;
    @include mobile-up {
        font-size: 16px;
    }
    &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
        &:hover {
            outline: none;
        }
    }
    &:focus, &:hover  {
            outline: 1px solid $secondary;            
            opacity: 0.9;
    }
}