@import '../../assets/styles/theme';
@import '../../assets/styles/breakpoints';

.layout-container {
    display: flex;
    overflow: hidden;
    height: 100vh;
    .nav-bar-toggle {
        position: absolute;
        top: 18px;
        left: 12px;
        z-index: 50;
    }
    .nav-bar-vertical-container {
        background-color: $secondary;
        position: relative;
        height: 100%;
        width: 100%;
        a {
            text-decoration: none;
            color: unset;
        }
        -webkit-box-shadow:
            0 2.8px 2.2px rgba(0, 0, 0, 0.034),
            0 6.7px 5.3px rgba(0, 0, 0, 0.048),
            0 12.5px 10px rgba(0, 0, 0, 0.06),
            0 22.3px 17.9px rgba(0, 0, 0, 0.072),
            0 41.8px 33.4px rgba(0, 0, 0, 0.086),
            0 100px 80px rgba(0, 0, 0, 0.12); /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
        -moz-box-shadow:
            0 2.8px 2.2px rgba(0, 0, 0, 0.034),
            0 6.7px 5.3px rgba(0, 0, 0, 0.048),
            0 12.5px 10px rgba(0, 0, 0, 0.06),
            0 22.3px 17.9px rgba(0, 0, 0, 0.072),
            0 41.8px 33.4px rgba(0, 0, 0, 0.086),
            0 100px 80px rgba(0, 0, 0, 0.12); /* Firefox 3.5 - 3.6 */
        box-shadow:
            0 2.8px 2.2px rgba(0, 0, 0, 0.034),
            0 6.7px 5.3px rgba(0, 0, 0, 0.048),
            0 12.5px 10px rgba(0, 0, 0, 0.06),
            0 22.3px 17.9px rgba(0, 0, 0, 0.072),
            0 41.8px 33.4px rgba(0, 0, 0, 0.086),
            0 100px 80px rgba(0, 0, 0, 0.12);
        transition: transform ease-in 3s;
        &.nav-max {
            max-width: 160px;
            @include desktop-lg-up {
                max-width: 190px;
            }
        }
        &.nav-min {
            max-width: 60px;
        }
        @include tablet-down {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 20;
            max-width: 160px;
        }
        .navbar {
            height: 90%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            a {
                text-decoration: none;
                color: unset;
            }
            .nav-list {
                margin-top: 85px;
                .nav-item {
                    display: flex;
                    padding: 12px 8px 12px 16px;
                    align-items: center;
                    margin-left: 4px;
                    color: $white;
                    .typography--body {
                        color: $white;
                    }
                    &:hover {
                        border-left: 4px solid $primary;
                        background-color: rgba(0, 0, 0, 0.1);
                        cursor: pointer;
                        margin-left: 0px;
                    }
                    &.active {
                        background-color: #faf9f6;
                        color: $secondary;
                        margin-left: 0px;
                        border-left: 4px solid $primary;
                        .typography--body {
                            color: $secondary;
                        }
                    }
                    p {
                        margin-left: 8px;
                    }
                    svg {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
            .helper-section {
                position: absolute;
                bottom: 10%;
                ul {
                    list-style-type: none;
                    padding: 16px;
                    li {
                        color: $white;
                        cursor: pointer;
                        display: block;
                        font-size: 14px;
                        text-decoration: underline;
                        margin-bottom: 6px;
                    }
                }
            }
        }
        .logo-container {
            height: 10%;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: #faf9f6;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            img {
                width: 85%;
                animation: fadeIn 1.5s;
                -webkit-animation: fadeIn 1.5s;
                -moz-animation: fadeIn 1.5s;
                -o-animation: fadeIn 1.5s;
                -ms-animation: fadeIn 1.5s;
                &.minified {
                    max-width: 60%;
                }
            }
        }
    }
    .page-container {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .nav-bar-horizontal-container {
            width: 100%;
            background-color: #f5f4f0;
            box-shadow: 0px 15px 10px -15px #c3c3c3;
            .navbar {
                max-width: $screen-desktop-lg;
                margin: 0 auto;
                @include desktop-wide-up {
                    max-width: 1480px;
                }
                padding: 16px 12px;
                @include mobile-up {
                    padding: 16px;
                }
                @include tablet-up {
                    padding: 16px 24px;
                }
                display: flex;
                align-items: center;
                .top-logo {
                    width: 120px;
                    margin: 15px 10px 10px 0;
                    @include tablet-down {
                        margin-left: 48px;
                    }
                    &:hover {
                        cursor: pointer;
                    }
                    img {
                        width: 100%;
                    }
                    @include tablet-up {
                        width: 160px;
                    }
                    @include desktop-lg-up {
                        width: 180px;
                    }
                }
                .active-route-label {
                    margin: 0px 10px 10px 0;
                    @include tablet-down {
                        margin-left: 48px;
                    }
                }
                .nav-item {
                    font-size: 16px;
                    line-height: 19px;
                    color: $secondary;
                    text-align: center;
                    padding: 0 16px;
                    @include tablet-down {
                        font-size: 13px;
                    }
                    @include tablet-only {
                        font-size: 13px;
                    }
                    span {
                        position: relative;
                        &:after {
                            position: absolute;
                            bottom: -8px;
                            left: 0;
                            width: 100%;
                            height: 2px;
                            background-color: $grey;
                        }
                    }
                    &:hover {
                        cursor: pointer;
                        span {
                            &:after {
                                content: '';
                            }
                        }
                    }
                    &.active {
                        span {
                            &:after {
                                content: '';
                                background-color: $primary !important;
                            }
                        }
                    }
                }
                nav {
                    flex-grow: 1;
                    padding: 16px 0px 16px 48px;
                    display: flex;
                    justify-content: flex-end;
                }
                .account-actions {
                    flex-grow: 1;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    @include tablet-down {
                        .button {
                            min-width: unset;
                            width: 100px;
                            font-size: 13px;
                        }
                    }
                    .nav-item {
                        margin-right: 24px;
                    }
                    .profile-section {
                        display: flex;
                        align-items: center;
                        font-weight: 500;
                        div {
                            font-size: 14px;
                            margin: 0 8px;
                            .logout-link {
                                text-decoration: underline;
                                cursor: pointer;
                                color: $primary;
                                text-align: right;
                            }
                            .user-role {
                                font-size: 12px;
                                opacity: 0.8;
                            }
                        }
                        .action-bar-avatar {
                            border-radius: 50%;
                            .avatar {
                                width: 40px;
                                height: 40px;
                            }
                            &:hover,
                            &:focus {
                                cursor: pointer;
                                opacity: 0.8;
                            }
                        }
                    }
                }
            }
        }
    }
    .page-content {
        flex-grow: 1;
        overflow: auto;
        position: relative;
        .container {
            position: relative;
            max-width: $screen-desktop-lg;
            margin: 0 auto;
            padding: 16px 12px;
            @include mobile-up {
                padding: 32px 16px;
            }
            @include tablet-up {
                padding: 48px 24px;
            }
            @include desktop-wide-up {
                max-width: 1480px;
            }
        }
    }
    footer {
        position: absolute;
        bottom: 0;
        z-index: 20;
        width: 100vw;
        .content {
            padding: 8px;
            @include mobile-up {
                padding: 16px;
            }
        }
    }
}
