@import '../../assets/styles/breakpoints';
@import '../../assets/styles/theme';
.dashboard {
    display: grid;
    grid-template-columns: 240px 1fr;
    grid-gap: 16px;
    margin-bottom: 24px;
    @include tablet-down {
        grid-template-columns: 1fr;
    }
    @include desktop-lg-up {
        grid-template-columns: 360px 1fr;
    }
    .selectors {
        display: flex;
        flex-direction: column;
        grid-gap: 16px;
        .selector {
            position: relative;
            animation: fadeIn 1.5s;
            -webkit-animation: fadeIn 1s;
            -moz-animation: fadeIn 1s;
            -o-animation: fadeIn 1s;
            -ms-animation: fadeIn 1s;
            //background: #faf9f6;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
            -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
            -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
            position: relative;
            border-radius: 8px;
            @include tablet-down {
                padding: 8px;
            }
            @include tablet-only {
                padding: 12px;
            }
            @include tablet-up {
                padding: 12px;
            }
            background-color: $white;
            .selector-title {
                display: flex;
                align-items: center;
                grid-gap: 12px;
                margin-bottom: 12px;
            }
            .option {
                position: relative;
                .details {
                    display: flex;
                    grid-gap: 12px;
                }
                svg {
                    position: absolute;
                    z-index: 2;
                    top: 0px;
                    right: 6px;
                    width: 18px;
                    padding: 4px;
                    color: $tertiary;
                    &:hover {
                        opacity: 0.7;
                        transform: scale(1.1);
                        cursor: pointer;
                    }
                }
            }
            .dropdown-indicator {
                svg {
                    width: 20px;
                    height: 20px;
                    &:hover {
                        opacity: 0.7;
                        transform: scale(1.1);
                        cursor: pointer;
                    }
                }
            }
            .format-container{   
                .format-selector {
                display: flex;
                flex-wrap: wrap;

                    .format {
                        flex-grow: 1;
                        min-width: 80px;
                        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
                        border: 1px solid $grey;
                        text-align: center;
                        padding: 4px 8px;
                        &.selected {
                            background-color: $info;
                            color: $white;
                            border: none;
                            &:hover {
                                background-color: $info;
                            }
                        }
                        font-size: 14px;
                        &:hover {
                            background-color: #f5f5f5;
                            cursor: pointer;
                        }
                    }
                }
            }
            .contract-dropdown{
                overflow-y: auto;
            }
            .filter-search {
                margin-top: 8px;
                margin-bottom: 8px;
                display: flex;
                position: relative;

                input {
                    border: 0;
                    background-color: #ebebeb;
                    border-radius: 4px;
                    padding: 6px 18px 6px 6px;
                    flex-grow: 1;
                    &:focus {
                        outline: 0.5px solid $grey;
                    }
                }
                svg {
                    width: 12px;
                    height: 12px;
                    position: absolute;
                    right: 4px;
                    top: 8px;
                    border-left: 1px solid $info;
                    padding-left: 2px;
                    color: $info;
                }
            }
            .contract-list {
                position: relative;
                display: flex;
                flex-direction: column;
                @include tablet-down {
                    margin-left: -8px;
                    margin-right: -8px;
                    margin-bottom: -8px;
                }
                @include tablet-only {
                    margin-left: -12px;
                    margin-right: -12px;
                    margin-bottom: -12px;
                }
                @include tablet-up {
                    margin-left: -12px;
                    margin-right: -12px;
                    margin-bottom: -12px;
                }
                max-height: 560px;
                .contract-item {
                    border-radius: 6px;
                    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
                    border-radius: 4px;
                    display: flex;
                    justify-content: space-between;
                    gap: 2px;
                    .detail-index-data{
                        display: flex;
                        flex-direction: row;
                    }
                    .detail-index {
                        border-radius: 50%;
                        width: 32px;
                        height: 32px;
                        background-color: $info;
                        color: white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 14px;
                        font-weight: 600;
                        margin-right: 1rem;
                    }
                    .action{
                        display: flex;
                        flex-direction: row;
                    }
                    @include tablet-down {
                        padding: 4px 8px;
                    }
                    @include tablet-only {
                        padding: 4px 12px;
                    }
                    @include tablet-up {
                        padding: 4px 12px;
                    }
                    &:hover {
                        cursor: pointer;
                        background-color: #ebebeb;
                    }
                    &:last-of-type {
                        border-bottom: none;                        
                        border-bottom-left-radius: 8px;
                        border-bottom-right-radius: 8px;
                    }
                    .icon-button {
                        padding: 4px;
                        svg {
                            width: 16px;
                            height: 16px;
                        }
                    }
                    &.selected {
                        background-color: #ebebeb;
                        border-left: 2px solid $info;
                    }
                    &.last{
                        margin-bottom: 25px
                    }
                }
            }
        }
        .station-container {
            .css-1s2u09g-control {
                min-height: 50px;
            }
            .css-1pahdxg-control {
                min-height: 50px;
            }
            .clear-container{
                display: flex;
                justify-content: flex-end;
                margin-top: 2%;
            .clear{
                font-size: 14px;
                line-height: 25.2px;
                font-weight: 400;
                width: fit-content;
                @include mobile-only { 
                    font-size: 13px;
                    line-height: 23.4px;
                }
                text-align: right;
                color:#B00020;
                cursor: pointer;
            }
        }
        }
    }
    .dashboard-content {
        display: flex;
        flex-direction: column;
        grid-gap: 16px; 
        .callsheet-icon {
            position: absolute;
            top: -1px;
            right: -1px;
            padding: 6px;
            display: flex;
            align-items: center;
            grid-gap: 6px;
            background-color: $primary;
            border-top-right-radius: 8px;
            border-bottom-left-radius: 8px;
            color: white;
            font-size: 14px;
            cursor: pointer;
            &:hover {
                background-color: #a59f78;
            }
        }       
    }
    .callsheet-panel {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 100;
        transition: transform ease-in 3s;
        background-color: white;
        width: 100vw;
        max-width: 480px;
        height: 100%;
        padding: 24px;
        overflow-y: auto;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        .panel-actions {
            position: absolute;
            top: 6px;
            right: 6px;
            display: flex;
            .icon-button {                
                svg {
                    width: 36px;
                    height: 36px;
                    @include tablet-down {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
        .callsheet-days {
            margin-top: 24px;
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 20px;
            .day-items {
                margin-top: 12px;
                display: grid;
                grid-gap: 6px;
                .call-item {
                    display: flex;
                    padding: 12px;
                    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
                    .call-item-detail {
                        flex-grow: 1;
                        .inline-item {
                            display: flex;
                            align-items: center;
                            grid-gap: 6px;
                            margin: 4px 0;
                            svg {
                                width: 16px;
                                height: 16px;
                                color: $tertiary;
                            }
                            span {
                                font-size: 14px;
                            }
                        }
                    }
                    .call-item-time {
                        max-width: 130px;
                        min-width: 130px;
                        padding: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-left: 2px solid $primary;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
