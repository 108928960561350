@import '../../assets/styles/breakpoints';
.stations,
.station {
    .contact-card-grid {
        display: flex;
        grid-gap: 12px;
        flex-wrap: wrap;
        margin-top: 24px;
        .contact-card {
            padding: 8px;
            border-radius: 6px;
            box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
            width: 320px;
            position: relative;
            word-wrap: break-word;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .dj-availables {
                display: flex;
                flex-wrap: wrap;
                grid-gap: 6px;
                margin-bottom: 12px;
                p {
                    display: flex;
                    align-items: center;
                }
                svg {
                    width: 14px;
                    height: 14px;
                    color: #007BFF;
                    margin-right: 4px;
                }
            }
            .primary-check,
            .priority-icon {
                position: absolute;
                top: 6px;
                right: 6px;
                width: 16px;
                height: 16px;
            }
            .card-actions {
                align-self: flex-end;
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
                .icon-button {
                    padding: 4px;
                    svg {
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }
    }
    .dj-availability {
        border: 1px solid #908960;
        background-color: #f5f4f0;
        border-radius: 8px;
        margin-bottom: 10px;
        padding: 0.5em;
        .button {
            min-width: 60px;
            width: 60px;
            font-size: 10px;
        }
    }
    .action-buttons {
        margin-top: 16px;
        display: flex;
        grid-gap: 12px;
        justify-content: flex-end;
        flex-wrap: wrap;
    }
    .user-assignment {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 8px;
        .user-assignment-item {
            display: grid;
            grid-template-columns: 30px 1fr;
            grid-gap: 8px;
            align-items: flex-start;
            padding: 6px 12px;
            border-radius: 6px;
            box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
        }
    }
    .modal-content {
        max-height: 60vh;
    }
    .input-select__menu-list {
        max-height: 120px;
    }

    .drop-down-button { 
        margin: 8px 0px;
        display: flex;
        gap: 8px;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        padding: 0.6em 1.2em;
        border: none;
        outline: none;    
    
        .icon-button {
            margin: 0px;
            padding: 2px;
            svg {
                width: 16px;
                height: 16px;
            }
        }
    }

    .export-dj-modal{
        min-height: 280px;
    }
}
