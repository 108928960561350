@import '../../assets/styles/breakpoints';
@import '../../assets/styles/theme';
.contracts {
    .modal-container{
        .modal-content{
            min-height: 30vh;
        }
        .strip-format-selector{
            margin-bottom: 5px;
        }
    }
    .contract-Archived {
        td {
            &:first-of-type {
                border-left: 4px solid $grey;
            }
        }
    }
    .contracts-list{
        .contract{
            &--success {
                td {
                    &:first-of-type {
                        border-left: 4px solid $success;
                    }
                }
            }
            &--error {
                
                td {
                    &:first-of-type {
                        border-left: 4px solid $error;
                    }
                }
            }
            &--info {
                td {
                    &:first-of-type {
                        border-left: 4px solid $info;
                    }
                }
            }
            &--warning {
                td {
                    &:first-of-type {
                        border-left: 4px solid $warning;
                    }
                }
            }
            &--primary {
                td {
                    &:first-of-type {
                        border-left: 4px solid $primary;
                    }
                }
            }
        }
    }
}
.strip-detail {
    display: flex;
    flex-direction: column;
    grid-gap: 0px;
    margin-top: 12px;
    &.loading {
        cursor:not-allowed;
        pointer-events: none;
        opacity: 0.7;
    }
    .detail-item-container{               
        &.hovering {
            opacity: 0;
            animation: fadeOut 1.5s;
            -webkit-animation: fadeOut 1s;
            -moz-animation: fadeOut 1s;
            -o-animation: fadeOut 1s;
            -ms-animation: fadeOut 1s;
        }
        &.fifth{
            margin-bottom: 35px;
        }
        .detail-item {
            border-radius: 6px;
            box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
            border-radius: 4px;
            padding: 4px 8px; 
            display: flex;
            cursor: move; /* fallback if grab cursor is unsupported */
            cursor: grab;
            cursor: -moz-grab;
            cursor: -webkit-grab;
            grid-gap: 8px;
            align-items: center;
            &.active {
                opacity: 0.6;
                cursor: grabbing;
                cursor: -moz-grabbing;
                cursor: -webkit-grabbing;
            }
            .detail-index {
                border-radius: 50%;
                width: 32px;
                height: 32px;
                background-color: $info;
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-weight: 600;
            }
            .detail-description {
                flex-grow: 1;
                display: flex;
                align-items: center;
                &--success {
                    border-left: 4px solid $success;
                }
                &--error {
                    border-left: 4px solid $error;
                }
                &--info {
                    border-left: 4px solid $info;
                }
                &--warning {
                    border-left: 4px solid $warning;
                }
                &--primary {
                    border-left: 4px solid $primary;
                }
                .strip-detail-description-metadata{
                    margin: 0 0.5rem;
                    flex-grow: 1;
                }
                .strip-detail-action {
                    display: flex;
                    align-items: center;
                    svg {
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }
    }
}
.contract{
    .client-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .action-container{
            margin-top: 0px!important;
        }
    }
    .client-selector-container{
        width: 100%;
        .selector {
            width: 100%;
            position:relative;
            .css-4ljt47-MenuList {
                margin-bottom: 8px;
            }
            .error{
                .css-1s2u09g-control{
                    border: 1px solid $error;
                }
            }
            .selector-title {
                display: flex;
                align-items: center;
                grid-gap: 12px;
                margin-bottom: 12px;
            }
            .option {
                position: relative;
                .details {
                    display: flex;
                    grid-gap: 12px;
                }
                svg {
                    position: absolute;
                    z-index: 2;
                    top: 0px;
                    right: 6px;
                    width: 18px;
                    padding: 4px;
                    color: $tertiary;
                    &:hover {
                        opacity: 0.7;
                        transform: scale(1.1);
                        cursor: pointer;
                    }
                }
            }
            .dropdown-indicator {
                svg{
                    width: 20px;
                    height: 20px;
                    &:hover{
                        opacity: 0.7;
                        transform: scale(1.1);
                        cursor: pointer;
                    }
                }
            }
        }
        .error-text {
            color: $error;
            margin-bottom: 0.025rem;
            font-size: 12px;
            width: 80%;
        }
    }
}