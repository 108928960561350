@import "/src/assets/styles/breakpoints";
@import "/src/assets/styles/theme";

.modal-container {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.45);
    overflow:hidden;
    .modal-wrapper {
        position: relative;        
        width: 90%;
        max-height: 80vh;
        animation: fadeIn 1.5s;
        -webkit-animation: fadeIn 1s;
        -moz-animation: fadeIn 1s;
        -o-animation: fadeIn 1s;
        -ms-animation: fadeIn 1s;
        background: $white;    
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        -webkit-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        -moz-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);        
        border-radius: 8px;  
        display: flex;
        flex-direction: column;
        margin-bottom: 6px;      
        &--small {
            max-width: 320px;
        }
        &--medium {
            max-width: 480px;
        }
        &--large {
            max-width: 640px;
        }
        .header {
            position: relative;
            z-index: 101;
            border-bottom: 3px solid $secondary;
            padding: 8px 16px;
            @include tablet-down {
                padding: 4px 8px;
            }
            .modal-close {
                position:absolute;
                top: -8px;
                right:-8px;
            }
        }
        .modal-content {                         
            overflow: auto; 
            &::-webkit-scrollbar {
                width: 4px;
            }
            
            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
            }
            
            &::-webkit-scrollbar-thumb {
                background-color: $secondary;
            } 
            padding: 16px;
            @include tablet-down {
                padding: 8px;
            }
            .action-bar {
                padding: 16px 16px; 
                margin: 16px -16px -16px -16px;
                @include tablet-down {
                    padding: 8px 8px; 
                    margin: 0 -8px -8px -8px;
                }
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
                background-color: #f5f4f0;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                button {
                    min-width: 80px;
                    font-size: 14px !important;
                }
                &>* {
                    margin: 0px 8px;
                    &:last-of-type {
                        margin-right: 0px;
                    }
                }
            }
        }
    }
}