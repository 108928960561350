@import '/src/assets/styles/_breakpoints.scss';
@import '/src/assets/styles/theme';

.searchbar {
    display: flex;
    animation: fadeIn 1.5s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;
    border-radius: 4px;
    height: 48px;
    margin-bottom: 12px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    .search-icon {
        background-color: $tertiary;
        padding: 12px;

        svg {
            font-size: 48px;
            color: $white;
        }
    }
    .search-input-wrapper {
        width: 100%;
        height: 100%;
        .option {
            position: relative;
            .details {
                display: flex;
                grid-gap: 12px;
            }
            svg {
                position: absolute;
                z-index: 2;
                top: 0px;
                right: 6px;
                width: 18px;
                padding: 4px;
                color: $tertiary;
                &:hover {
                    opacity: 0.7;
                    transform: scale(1.1);
                    cursor: pointer;
                }
            }
        }
        .css-4ljt47-MenuList {
            position: relative;
            z-index: 150;
            max-height: 20vh;
        }
        .content-loading {
            position: absolute;
            right: 6px;
            top: 12px;

            img {
                width: 60px;
            }

            svg {
                width: 48px;
                color: green;
            }
        }
    }
    .clear-container {
        display: flex;
        justify-content: flex-end;
        margin-top: 2%;

        .clear {
            font-size: 14px;
            line-height: 25.2px;
            font-weight: 400;
            width: fit-content;

            @include mobile-only {
                font-size: 13px;
                line-height: 23.4px;
            }

            text-align: right;
            color:#B00020;
            cursor: pointer;
        }
    }
}