@import '/src/assets/styles/breakpoints';
@import '/src/assets/styles/theme';

.input-field-container {
    position: relative;
    margin-top: 0.5rem;
    padding-bottom: 1.2rem;
    height: 36px;
    width: 100%;
    display: flex;
    .input-field {
        width: 100%;
        border: 1px solid $secondary;
        font-size: 16px;
        border-radius: 4px;
        outline: none;
        padding: 0.8rem;
        background: none;
        &:focus {
            border: 1.5px solid $primary;
        }
        &.error {
            border: 1px solid $error;
        }
    }
    .error-text {
        color: $error;
        position: absolute;
        bottom:1px;
        left: 5px;
        font-size: 12px;
        width: 80%;
    }
    .input-label-outline {
        position: absolute;
        left: 1rem;
        top: 0.4rem;
        padding: 0 0.25rem;
        color: $secondary;
        transition: 0.3s;
    }
    .input-field:focus+ .input-label-outline {
        top: -0.5rem;
        left: 0.8rem;
        color: $primary;
        font-size: 12px;
        font-weight: 500;
        z-index: 1;
        background-color: white;
    }
    .input-field:not(:placeholder-shown)+ .input-label-outline{
        top: -0.5rem;
        left: 0.8rem;
        color: $primary;
        font-size: 12px;
        font-weight: 500;
        z-index: 1;
        background-color: white;
      }
}
