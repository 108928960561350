.page-loader {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .loader-container {
        width: 100%;
        max-width: 140px;
        img {
            width: 100%;
        }
    }
}
