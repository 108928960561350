.hidden {
    display: none;
}
.hidden-mobile {
    @include tablet-down {
        display: none;
    }
}
.hidden-tablet {
    @include tablet-only {
        display: none;
    }
}
.hidden-desktop {
    @include tablet-up {
        display: none;
    }
}

@keyframes pulse-black {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.paper {
    animation: fadeIn 1.5s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;
    //background: #faf9f6;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    position: relative;
    border-radius: 8px;
    @include tablet-down {
        padding: 14px;
    }
    @include tablet-only {
        padding: 24px;
    }
    @include tablet-up {
        padding: 32px;
    }
    background-color: $white;
}

.page {
    flex-grow: 1;
    padding: 24px 16px 24px;
    @include mobile-up {
        padding: 36px 24px 24px;
    }
    @include tablet-up {
        padding: 48px 24px 24px;
    }
    position: relative;
}
.text-action {
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}
.form-grid-half {
    display: grid;
    grid-column-gap: 16px;
    grid-row-gap: 8px;
    grid-template-columns: 1fr;
    align-items: flex-start;
    @include mobile-up {
        grid-template-columns: 1fr 1fr;
    }
}
.form-grid-third {
    display: grid;
    grid-column-gap: 16px;
    grid-row-gap: 8px;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
    @include desktop-lg-up {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
.action-container {
    margin-top: 24px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .button {
        margin-right: 8px;
        &:last-child {
            margin-right: 0px;
        }
    }
}

.text-center {
    text-align: center;
}
.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}
.font-100 {
    font-weight: 100;
}
.font-200 {
    font-weight: 200;
}
.font-300 {
    font-weight: 300;
}
.font-400 {
    font-weight: 400;
}
.font-500 {
    font-weight: 500;
}
.font-600 {
    font-weight: 600;
}
.font-700 {
    font-weight: 700;
}
.font-800 {
    font-weight: 800;
}
.font-900 {
    font-weight: 900;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
.d-block {
    display: block;
}
.d-flex {
    display: flex;
}
.popup-content {
    padding: 30px;
}
.container-tablet {
    max-width: $screen-tablet;
    margin: 24px auto;
}
.grid-item-mobile {
    display: grid;
    grid-template-columns: 1fr 60px;
}
.clickable-grid-custom-item > * {
        pointer-events: none;
}
.grid-actions {    
    pointer-events: all;
    .icon-button .icon {
        width: 16px;
    }
    @include tablet-up {
        display: flex;
    }
}
.grid-word-break {
    word-break: break-all;
}
.page-title {
    position: relative;
    &:after {
        position: absolute;
        left: -12px;
        width: calc(100% + 24px);
        bottom: -6px;
        content: '';
        height: 3px;
        background-color: $tertiary;
    }
}
.vertical-line {
    height: 1em;
    width: 1px;
    background-color: white;
    margin: 0 8px;
}