.feedback-editor {
    flex-grow: 1;
    position: relative;
    .input-select__menu {
        z-index: 10 !important;
    }
    .input-area {
        position: relative;
        .process-indicator {
            position: absolute;
            bottom: 0px;
            right: 6px;
            img {
                max-width: 40px;
                width: 100%;
            }
        }
        .text-area-field-container {
            min-height: 360px;
            textarea {
                min-height: 340px;
            }
        }
    }
}