.contracts-feedbacks {
    margin-top: 24px;
    .feedback-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
    }
    .feedback {        
        position:relative;
        border-bottom: 3px solid;
        padding-bottom: 24px;
        margin-bottom: 16px;
        overflow-wrap: break-word;
        &.active {
            &::after{
                position:absolute;
                content: 'active';
                top: 60px;
                right: 12px;
                color: white;
                width: 50px;
                height: 20px;
                font-size: 12px;
                text-align: center;
                background-color:deepskyblue;
            }
        }
        &:last-of-type {
            border-bottom: none;
        }
        .feedback-action {
            position: absolute;
            top: 0;
            right: 0;
            display: flex; 
            z-index: 1000;                  
            .icon-button {            
                .icon {
                    width: 16px;
                    padding: 4px;
                }
            }
        } 
    }
}