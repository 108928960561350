input[type="checkbox"] {
    &.toggle {
        position: relative;
        width: 32px;
        height: 17px;
        -webkit-appearance: none;
        -webkit-appearance: none;
        background: rgba(120, 120, 128, 0.16);
        outline: none;
        cursor: pointer;
        border-radius: 12px;
        box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
        transition: background 300ms linear;

        // circle51
        &::before {
        position: absolute;
        content: "";
        width: 17px;
        height: 17px;
        left: 1px;
        border-radius: 12px;
        background-color: #fff;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
        transition: left 300ms;
        }
        // move to right
        &:checked {
        background: #3b94ff;
        &::before {
            left: 16px;
            background-color: #fff;
        }
        }
    }    
  }