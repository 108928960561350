.landing-container {
    background-color: #908960;
    position: relative;
    z-index: 2;
    &:after {
        position:absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        content: '';
        opacity: 0.5;
        background: url(../../assets/images/radio.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        z-index: -1;
    }
    .page-content {
        display: flex;
        align-items: center;
    }
}