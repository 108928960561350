@import '/src/assets/styles/theme';

.select-container {
    position: relative;
    width: 100%;
    padding-bottom: 1.2rem;
    .input-select__control {
        min-height: 35px !important;
        margin-top: 0.6rem;
        background-color: transparent !important;
        border: 1px solid $secondary !important;
        &--is-focused {
            border: 1px solid $primary !important;
            outline: none !important;
            box-shadow: none !important;
        }
        &--menu-is-open {
            border: 1px solid $primary !important;
            outline: none !important;
            box-shadow: none !important;
        }
    }
    .error-select__control {
        margin-top: 0.5rem;
        background-color: transparent !important;
        border: 1px solid $error !important;
        &--is-focused {
            border: 1px solid $primary !important;
            outline: none !important;
            box-shadow: none !important;
        }
        &--menu-is-open {
            border: 1px solid $primary !important;
            outline: none !important;
            box-shadow: none !important;
        }
    }
    .error-text {
        color: $error;
        position: absolute;
        bottom: 1px;
        left: 5px;
        font-size: 12px;
        width: 80%;
    }
}
