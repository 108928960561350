.contract-report {
    margin-top: 24px;
    .report-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .icons-container{
        display: flex;
        flex-direction: row;
    }
    .report-paper {
        margin-top: 24px;
        padding: 24px 16px;
        border: 1px solid #DFDFDF;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;            
            background-color: rgba(0,0,0,0.03);
        }
    }
    .report-icons{
        display: flex;
        flex-direction: row;
    }
    .week-spin{
        margin-top: -20px;
    }
    .week-input{
        margin-top: -10px;
    }
}