@import '/src/assets/styles/_breakpoints.scss';
@import '/src/assets/styles/theme';

.clickable-grid-custom-item{
    .icon-button {
        svg {
            width: 16px;
            height: 16px;
        }
    }
}
.mailing-modal {
    min-height: 30vh;

    .mailing-toggler{
        display: flex;
        justify-content: flex-end;
    }
}
.selected-stations {
    position: relative;
    display: flex;
    flex-direction: column;
    @include tablet-down {
        margin-left: -8px;
        margin-right: -8px;
        margin-bottom: -8px;
    }
    @include tablet-only {
        margin-left: -12px;
        margin-right: -12px;
        margin-bottom: -12px;
    }
    @include tablet-up {
        margin-left: -12px;
        margin-right: -12px;
        margin-bottom: -12px;
    }
    max-height: 30vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
    & {
        scrollbar-width: none;
    }
    & {
        -ms-overflow-style: none;
    }
    .station-item {
        border-radius: 6px;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        gap: 2px;
        .detail-index-data{
            display: flex;
            flex-direction: row;
        }
        .detail-index {
            border-radius: 50%;
            width: 32px;
            height: 32px;
            background-color: $info;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 600;
            margin-right: 1rem;
        }
        .action{
            display: flex;
            flex-direction: row;
        }
        @include tablet-down {
            padding: 4px 8px;
        }
        @include tablet-only {
            padding: 4px 12px;
        }
        @include tablet-up {
            padding: 4px 12px;
        }
        &:hover {
            cursor: pointer;
            background-color: #ebebeb;
        }
        &:last-of-type {
            border-bottom: none;                        
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
        .icon-button {
            padding: 4px;
            svg {
                width: 16px;
                height: 16px;
            }
        }
    }
}