@import '/src/assets/styles/breakpoints';
@import '/src/assets/styles/theme';
.date-input-container {    
    margin-top: 0.5rem;
    padding-bottom: 1.2rem;
    position: relative;
    @include mobile-only {
        padding-bottom: 2.2rem;
    }
    height: 36px;
    width: 100%;
    .date-fields {
        display: flex;      
        border-radius: 4px;
        outline: 1px solid $secondary;
        height: 100%;
        padding-left: 0.8rem;
        padding-right: 0.8rem;
        &:focus-within {
            outline: 1.5px solid $primary;
        }
        &.error {
            outline: 1px solid $error;
        }        
        span {
            font-size: 24px;
            font-weight: 300;
        }
        input {
            border: none;
            font-size: 16px;
            border-radius: 4px;
            outline: none;
            padding-top: 0.8rem;
            padding-bottom: 0.8rem;
            background: none;
            &.month, &.day {
                max-width: 30px;
            }
            &.year {
                max-width: 60px;
            }
        }        
    }        
    .error-text {
        color: $error;
        position: absolute;
        bottom:1px;
        left: 5px;
        font-size: 12px;
        width: 80%;
    }
}