.report-generator {

    .report-content{
        margin-top: 24px;
        [role="application"]{
            aspect-ratio: 0.71!important;
            margin: auto!important;
            height: auto!important;
            min-width: 900px!important;
        }

    }
    .contracts-feedbacks {
        max-height: 570px;
        overflow-y: auto;
        padding: 12px !important;
    }
    .report-title {
        display: flex;
        gap: 6px;
        justify-content: space-between;
    }
}
